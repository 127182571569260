<template>
  <div v-if="this.config.layout" :class="this.config.layout">
    <div style="display: flex;">
      <div style="width: 25%">


        <!-- Header -->
        <img v-if="this.config.logo" :src="'../img/'+this.config.logo" alt="logo" style="max-height: 200px;">

        <div style="display:flex; justify-content: space-between">
          <div v-if="eventData && eventData.NV>1">Quedan {{ eventData ? eventData.NV : 0 }} vueltas</div>
          <div v-if="eventData && eventData.NV==1">Última vuelta</div>
          <div v-if="!eventData || eventData.NV==0">&nbsp;</div>
        
          <div style="zzdisplay:aaflex">Mejor Vuelta
            <table>
              <tr>
                <th>Piloto</th>
                <th>Vuelta</th>
                <th>Tiempo</th>
              </tr>

              <!-- resultado es array, pero solo devuelve 1, por si devolviera mas...  -->
              <tr v-for="bestLap in bestLaps" :key="bestLap.D">
                <td>#{{ bestLap.D }} {{ bestLap.N.replace(/[a-z]/g, '') }}</td>
                <td>{{ bestLap.VR }}</td>
                <td>{{ bestLap.T }}</td>
              </tr>
            </table>
          </div>
        </div>

        <p><br></p>


        <!-- Classification table -->
        <table width="100%" class="classificationTable">
          <tr>
            <th v-for="column in columns" :key="column.field" :style="{ 'text-align': column.label === 'Piloto' ? 'left' : 'center' }">{{ column.label }}</th>
          </tr>
          <tr v-for="row in cronodata" :key="row.D">
            <td align="right">{{ row.O }}</td>
            <td>{{ row.D }}</td>
            <td align="left">{{ row.SI }}</td>
            <td align="right">{{ row.T.includes('a') ? row.G1 : row.T }}</td>
          </tr> 
        </table>


        <!-- version con buefy 
        <b-table :data="cronodata" :columns="columns" :mobile-cards="false"
          :bordered="false" backend-filtering style="border:0px">
        </b-table>
        -->

        <footer>
          <p>Powered by Crono-Jerez © {{ new Date().getFullYear() }}</p>
        </footer>

      </div>
    </div>

  </div>
  <div v-else>
    <h1>CRONODATA</h1>
    <p>Cargando configuración...</p>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Table from 'buefy'
// import Buefy from 'buefy' // import all buefy components
import 'buefy/dist/buefy.css'

Vue.use(Table)
// Vue.use(Buefy) // in case import all buefy components

export default Vue.extend({
  name: 'CronoData',

  props: {
    // msg: String,
  },

  data() {
    return {
      host: 'http://localhost',
      port: 3000, // será 80 en produccion

      interval: 0,

      config: {}, // configuracion visual

      eventData: null, // numero vueltas de la prueba
      bestLaps: [], // mejores vueltas (es un array aunque solo devuelve 1 vuelta)
      cronodata: [], // clasificacion carrera
      totalVueltas: 0, // total vueltas de la carrera. // inyectado por el servidor en la respuesta (no mostrado ahora)

      columns: [
        {
          field: 'O',
          label: 'Ord',
          width: '40',
          numeric: true,
          headerClass: 'esbk',
          cellClass: 'esbk',
        },
        {
          field: 'D',
          label: '#',
          centered: true,
          headerClass: 'esbk',
          cellClass: 'esbk',
        },
        {
          field: 'SI',
          label: 'Piloto',
          numeric: false,
          centered: false,
          headerClass: 'esbk',
          cellClass: 'esbkLeft',
        },
        {
          field: 'T',
          label: 'Tiempo',
          numeric: true,
          headerClass: 'esbk',
          cellClass: 'esbk',
        },
      ],
    }
  },

  mounted() {

    if (process.env.NODE_ENV === 'production') {
      this.host = 'http://data.crono-jerez.com'
      this.port = 80
    }

    this.fetchConfig()
    this.fetchData()

    this.interval = setInterval(this.fetchData, 1000);

  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {

    // fetch classification data
    async fetchData() {
      // let response = await fetch('http://localhost:3000/cronodata?' + Date.now());
      let response = await fetch(this.host+':'+this.port+'/cronodata?' + Date.now());
      
      if (response.status !== 200) {
        console.error('Error fetching data');
        // a veces da un error puntual. En ese caso, saltamos esta llamada y esperamos a la siguiente.
        // saltamos para no pintar la clasificacion en blanco por un momento.
        // se pintará blanco cuando la respuesta sea 200 y no haya datos intencionadamente.
        return;
      }

      let fullData = await response.json();

      this.eventData = fullData.PRUDATAUX[0]
      this.bestLaps = fullData.MEJORES_VUELTAS
      this.cronodata = fullData.LIVE
      this.totalVueltas = fullData.TOTAL_VUELTAS || 0

      // console.log("recibido cronodata", this.cronodata)
    },


    // fetch visual config data
    async fetchConfig() {
      let response = await fetch(this.host+':'+this.port+'/dataconfig');
      
      if (response.status !== 200) {
        console.error('Error fetching configdata');
        return;
      }

      this.config = await response.json();
    },
  }

});


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

/* diferentes layouts: 'default' o 'esbk' */
.default {
  background-color: black;
  // background-color: rgba(128, 128, 128, 0.5);
  color: yellow;
}
/* hay que hacer 2 clases iguales, para forzar la columna piloto a la izquierda (en caso de usar b-table)*/
.defaultLeft {
  background-color: black;
  // background-color: rgba(128, 128, 128, 0.5);
  color: yellow;
  text-align: left !important;
}



.esbk {
  background-color: white;
  color: #121036;
  font-weight: bold;
}
.esbkLeft {
  background-color: white;
  color: #121036;
  text-align: left !important;
}



.classificationTable td {
  padding: 0.1em 0.5em;
}

</style>
