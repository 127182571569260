<template>
    <div class="container">
        <h1 class="title">CronoData Panel</h1>
        <h3>Login</h3>
        

        <form method="post" @submit.prevent="login" enctype="multipart/form-data">
            <b-field label="Username" type="is-success">
                <b-input name="username" v-model="form.username" maxlength="30"></b-input>
            </b-field>
            <b-field label="Password">
                <b-input name="password" v-model="form.password" type="password" password-reveal></b-input>
            </b-field>
            <b-button type="is-primary is-light" native-type="submit">Login</b-button>
        </form>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            form: {
                username: '',
                password: ''
            }
        }
    },
    methods: {

        login() {
            fetch('/loginbackend', {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                headers: {
                "Content-Type": "application/json",
                },
                redirect: "follow", // manual, *follow, error
                body: JSON.stringify(this.form), // body data type must match "Content-Type" header
            });

        }
    }
}
</script>
